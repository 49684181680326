import "../styles/pages/single-text-page.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Breadcrumbs from "components/Breadcrumbs";
import SectionTitle from "components/SectionTitle";

const TextPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="text-page">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="text-page__content">
                  <div className="row justify-content-center">
                     <div
                        className="col-xl-10"
                        dangerouslySetInnerHTML={{
                           __html: pageContext.content,
                        }}
                     ></div>
                  </div>
               </div>
            </div>
         </section>
         {pageContext.data.elements && (
            <div className="text-page__additional">
               {pageContext.data.elements.map((item, index) => {
                  if (item.type === "button") {
                     return (
                        <a
                           href={item.link}
                           className="button text-page__additional-button"
                           key={index}
                        >
                           {item.text}
                        </a>
                     );
                  } else if (item.type === "image") {
                     return (
                        <img
                           src={item.image?.sourceUrl}
                           alt={item.image?.altText}
                           key={index}
                           className="img-fluid text-page__additional-img"
                        />
                     );
                  } else {
                     return null;
                  }
               })}
            </div>
         )}
      </Layout>
   );
};

export default TextPage;
